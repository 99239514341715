class FullHeightPanels {
  resizePanels() {
    // This value is set up in the css
    const INITIAL_MAX_HEIGHT = 360;
    const showingsScroll = document.querySelector('.showings_container #showings');
    const listingsScroll = document.querySelector('#listings_box #listings');
    let computedListingsMaxHeight, listingsMaxHeight, computedShowingsMaxHeight, showingsMaxHeight = null;
    if (listingsScroll) {
      computedListingsMaxHeight = getComputedStyle(listingsScroll).maxHeight;
      listingsMaxHeight = parseInt(computedListingsMaxHeight);
    }

    if (showingsScroll) {
      computedShowingsMaxHeight = getComputedStyle(showingsScroll).maxHeight;
      showingsMaxHeight = parseInt(computedShowingsMaxHeight);
    }

    const isShowingsShort = !showingsScroll || showingsScroll.offsetHeight < INITIAL_MAX_HEIGHT;
    const isListingsShort = !listingsScroll || listingsScroll.offsetHeight < INITIAL_MAX_HEIGHT;
    const footerHeight = document.getElementById('footer') ? document.getElementById('footer').offsetHeight : 0;
    const viewportHeightWithoutFooter = window.innerHeight - footerHeight;
    const emptySpace = viewportHeightWithoutFooter - document.getElementById('content').getBoundingClientRect().bottom;

    const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);

    if (vw >= 1680) {
      if (listingsScroll) {
        const listingsBottom = document.querySelector('#listings').getBoundingClientRect().bottom;
        listingsScroll.style.maxHeight = Math.max(viewportHeightWithoutFooter - listingsBottom - 30 + listingsMaxHeight, INITIAL_MAX_HEIGHT) + 'px';
      }
      if (showingsScroll) {
        const showingsBottom = document.querySelector('.showings_container').getBoundingClientRect().bottom;
        showingsScroll.style.maxHeight = Math.max(viewportHeightWithoutFooter - showingsBottom - 30 + showingsMaxHeight, INITIAL_MAX_HEIGHT) + 'px';
      }
      return;
    }

    if (!isShowingsShort && !isListingsShort) {
      const h = (showingsMaxHeight + listingsMaxHeight) / 2;
      const resultHeight = Math.max(h + emptySpace/2, INITIAL_MAX_HEIGHT) + 'px';
      showingsScroll.style.maxHeight = resultHeight;
      listingsScroll.style.maxHeight = resultHeight;
    } else if (isShowingsShort) {
      if (listingsScroll) {
        listingsScroll.style.maxHeight = Math.max(listingsMaxHeight + emptySpace, INITIAL_MAX_HEIGHT) + 'px';
      }
    } else if (isListingsShort) {
      showingsScroll.style.maxHeight = Math.max(showingsMaxHeight + emptySpace, INITIAL_MAX_HEIGHT) + 'px';
    }
  }

  bindEvents() {
    document.addEventListener('html-updated', () => {
      this.resizePanels();
    });
    window.onresize = () => {
      this.resizePanels();
    };
  }

  init() {
    this.resizePanels();
    this.bindEvents();
  }
}

export default FullHeightPanels;
