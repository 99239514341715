class ExpandShowingsControls {

  constructor() {
    this.expandIconClickHandler = this.expandIconClickHandler.bind(this);
  }

  expandShowing(showing) {
    const icons = showing.querySelectorAll('.js-expand-showing-info i');
    showing.querySelector('.js-expandable-content').classList.remove('collapsed');
    icons[1].classList.remove('hidden');
    icons[0].classList.add('hidden');
  }

  collapseShowing(showing) {
    const icons = showing.querySelectorAll('.js-expand-showing-info i');
    showing.querySelector('.js-expandable-content').classList.add('collapsed');
    icons[0].classList.remove('hidden');
    icons[1].classList.add('hidden');
  }

  setAllShowingsState(expanded) {
    document.querySelectorAll('.js-showing-item').forEach((showing) => {
      if (!expanded) {
        this.collapseShowing(showing);
      } else {
        this.expandShowing(showing);
      }
    });
  }

  expandIconClickHandler(e) {
    const icon = e.currentTarget;
    const showing = icon.closest('.js-showing-item');
    showing.querySelector('.js-expandable-content').classList.toggle('collapsed');
    showing.querySelectorAll('.js-expand-showing-info i').forEach(i => {
      i.classList.toggle('hidden');
    });
  }

  initExpandIcons(containerSelector) {
    document.querySelectorAll(containerSelector + ' .js-expand-showing-info').forEach(icon => {
      icon.removeEventListener('click', this.expandIconClickHandler);
      icon.addEventListener('click', this.expandIconClickHandler);
    });
  }

  bindEvents() {
    document.querySelectorAll('.js-collapse-all-showings').forEach(el => {
      el.addEventListener('click', e => {
        e.preventDefault();
        this.setAllShowingsState(false);
        el.blur();
        document.dispatchEvent(new CustomEvent('sidebar:popup-close'));
        return false;
      });
    });

    document.querySelectorAll('.js-expand-all-showings').forEach(el => {
      el.addEventListener('click', e => {
        e.preventDefault();
        this.setAllShowingsState(true);
        el.blur();
        document.dispatchEvent(new CustomEvent('sidebar:popup-close'));
        return false;
      });
    });
  }

  init() {
    this.bindEvents();
  }
}

export default ExpandShowingsControls;
