import LayoutUtils from 'src/utils/layout_utils';
import FetchUtils from 'src/utils/fetch_utils';
import autoComplete from '@tarekraafat/autocomplete.js';
import '@tarekraafat/autocomplete.js/dist/css/autoComplete.02.css';

class TwoWayMessaging {
  #windowSelector = '.js-two-way-messaging-wrap';
  #csrf = document.querySelectorAll('meta[name=csrf-token]')[0].getAttributeNode('content').value;
  #upLoading;
  #scrollTimer = null;
  #switchery = null;
  #isOpen = false;
  #last_marked_as_read_message_id = null;
  static get maxMessageSymbolsCount() {
    return document.querySelector('.js-symbols-left').dataset.maxCount;
  }

  constructor() {
    this.closeWindowHandler = this.closeWindowHandler.bind(this);
    this.sendMessageHandler = this.sendMessageHandler.bind(this);
    this.markUnreadHandler = this.markUnreadHandler.bind(this);
    this.insertFieldHandler = this.insertFieldHandler.bind(this);
    this.messagesScrollHandler = this.messagesScrollHandler.bind(this);
    this.messageKeyupHandler = this.messageKeyupHandler.bind(this);
    this.refreshChatHandler = this.refreshChatHandler.bind(this);
    this.openMessagingHandler = this.openMessagingHandler.bind(this);
  }

  fillSendMessageField(value) {
    document.querySelector('.js-send-message-value').value = value;
  }

  scrollToBottomMessages(id = null) {
    const messages = document.querySelectorAll('.js-message');
    if (id) {
      document.querySelector(`.js-message[data-id=${id}]`).scrollIntoView({
        behavior: 'instant',
        block: 'nearest',
        inline: 'nearest'
      });
      // Scroll down several pixels to have the ability scroll up and fetch previous messages.
      document.querySelector('.js-messages').scrollBy(0, 10);
    } else {
      if (messages.length > 1) {
        messages[messages.length - 1].scrollIntoView({
          behavior: 'smooth',
          block: 'nearest',
          inline: 'nearest'
        });
      }
    }
  }

  refreshChatHandler() {
    const messages = document.querySelectorAll('.js-message');
    const win = document.querySelector(this.#windowSelector);
    const fromId = messages.length > 0 ? messages[messages.length - 1].dataset.id : null;
    this.fetchMessages(win.dataset.url, {from_id: fromId, direction: 'down'}, false, true);
  }

  sendMessageHandler() {
    const win = document.querySelector(this.#windowSelector);
    const message = document.querySelector('.js-send-message-value').value.trim();
    if (message !== '' && message.length <= TwoWayMessaging.maxMessageSymbolsCount) {
      const data = {message};
      this.showLoader();
      fetch(win.dataset.sendUrl, {method: 'POST', body: JSON.stringify(data), headers: {
        'x-csrf-token': this.#csrf,
        'Content-Type': 'application/json; charset=utf-8'
      }}).then(FetchUtils.checkResponseStatus)
        .then((resp) => {
          return resp.json();
        }).then(json => {
          this.hideWarningMessage();
          this.hideLoader();
          this.fillSendMessageField('');
          this.setCharactersLeft(0);
          document.querySelector('.js-messages').insertAdjacentHTML('beforeend', json.html);
          this.scrollToBottomMessages();
        }).catch((err) => {
          FetchUtils.handleResponseError(err);
        });
    } else {
      this.checkExceededSymbolsCount(message.length);
    }
  }

  markUnreadHandler(e) {
    if (e.target.checked) {
      const win = document.querySelector(this.#windowSelector);
      const id = this.getFirstVisibleIncomingMessage();
      if (id) {
        this.#last_marked_as_read_message_id = null;
        let url = new URL(win.dataset.unreadUrl, document.baseURI);
        let params = new URLSearchParams(url.search);
        params.append('message_id', id);
        url.search = params.toString();
        fetch(url, {method: 'PUT', headers: {
          'x-csrf-token': this.#csrf
        }}).then(FetchUtils.checkResponseStatus)
          .catch((err) => {
            FetchUtils.handleResponseError(err);
          });
      }
    } else {
      this.markAsRead();
    }
  }

  insertFieldHandler(e) {
    const value = e.target.value;
    const select = document.querySelector('.js-other-listings-select');
    const oldValue = document.querySelector('.js-send-message-value').value;
    if (value.includes('other-listing')) {
      LayoutUtils.show(select);
    } else {
      LayoutUtils.hide(select);
      this.fillSendMessageField(oldValue + ' ' + value + ' ');
    }
  }

  checkShowingStatus() {
    const win = document.querySelector(this.#windowSelector);
    const id = win.dataset.current_uid;
    const url = win.dataset.showingPath;
    fetch(`${url}?id=${id}`).then(FetchUtils.checkResponseStatus)
      .then((resp) => {
        return resp.json();
      }).then(json => {
        if (json.html) {
          const showing = document.getElementById(`showing_${json.id}`);
          showing.innerHTML = json.html;
          LayoutUtils.highlight(showing);
          document.dispatchEvent(new CustomEvent('html-updated', {detail: {container: '#showings_box'}}));
          if (typeof toggleLeadInitial !== 'undefined') {
            toggleLeadInitial();
          }
        }
      }).catch((err) => {
        FetchUtils.handleResponseError(err);
      });
  }

  closeWindowHandler() {
    this.checkShowingStatus();
    document.querySelector(this.#windowSelector).remove();
    this.#isOpen = false;
  }

  messagesScrollHandler() {
    if(this.#scrollTimer) {
      clearTimeout(this.#scrollTimer);
    }

    this.#scrollTimer = setTimeout(() => {
      const win = document.querySelector(this.#windowSelector);
      const scrollable = document.querySelector('.js-messages');
      const messages = document.querySelectorAll('.js-message');

      if (messages.length > 0) {
        if (scrollable.scrollTop === 0 && this.#upLoading) {
          const fromId = messages[0].dataset.id;
          this.fetchMessages(win.dataset.url, {from_id: fromId, direction: 'up'});
        } else if (scrollable.offsetHeight + scrollable.scrollTop >= scrollable.scrollHeight) {
          const fromId = messages[messages.length - 1].dataset.id;
          if (fromId) {
            this.fetchMessages(win.dataset.url, {from_id: fromId, direction: 'down'});
          }
        } else {
          this.markAsRead();
        }
      }
    }, 300);
  }

  setCharactersLeft(alreadyAddedCount) {
    const left = TwoWayMessaging.maxMessageSymbolsCount - alreadyAddedCount;
    let msg = '';
    if (left >= 0) {
      msg = 'Characters left: ' + left;
    } else {
      msg = 'Characters left: 0';
    }

    document.querySelector('.js-symbols-left').innerHTML = msg;
    return left;
  }

  checkExceededSymbolsCount(alreadyAddedCount) {
    const left = this.setCharactersLeft(alreadyAddedCount);
    if (left < 0) {
      this.disableSendButton('Maximum number of symbols exceeded');
    } else {
      this.enableSendButton();
    }
  }

  messageKeyupHandler(e) {
    this.checkExceededSymbolsCount(e.target.value.length);
  }

  bindAfterOpenEvents() {
    document.querySelector('.js-close-messages').addEventListener('click', this.closeWindowHandler);
    document.querySelector('.js-send-message').addEventListener('click', this.sendMessageHandler);
    document.querySelector('.js-send-message-value').addEventListener('keyup', this.messageKeyupHandler);
    document.querySelector('.js-send-message-value').addEventListener('change', this.messageKeyupHandler);
    document.querySelector('.js-mark-unread').addEventListener('change', this.markUnreadHandler);
    document.querySelector('.js-insert-field').addEventListener('change', this.insertFieldHandler);
    document.querySelector('.js-messages').addEventListener('scroll', this.messagesScrollHandler);
    document.querySelector('.js-refresh-chat').addEventListener('click', this.refreshChatHandler);
  }

  initOtherListingSelect(url) {
    new autoComplete({
      selector: '.js-other-listings-select',
      placeHolder: 'Search Listing',
      debounce: 300,
      cache: true,
      data: {
        src: async (query) => {
          try {
            LayoutUtils.show(document.querySelector('.js-other-listing-select-wrap .js-endless-scroll-loader'));
            const type = document.querySelector('.js-insert-field').value.replace('other-listing-','');
            const source = await fetch(`${url}?q=${query}&type=${type}`);
            return await source.json();
          } catch (error) {
            return error;
          }
        },
        keys: ['label']
      },
      resultItem: {
        highlight: 'autocomplete-result-highlight',
        element: (item, data) => {
          item.innerHTML = `
            <div>
              ${data.value.label}
            </div>
            <div class="autocomplete-listing-summary">
              ${data.value.summary}
            </div>`;
        }
      },
      resultsList: {
        class: 'autocomplete-results-list',
        position: 'beforebegin',
        element: (list, data) => {
          if (!data.results.length) {
            const message = document.createElement('div');
            message.setAttribute('class', 'no-result');
            message.innerHTML = '<span>No Listings Found</span>';
            list.appendChild(message);
          }
        },
        noResults: true
      }
    });

    document.querySelector('.js-other-listings-select').addEventListener('selection', (event) => {
      const oldValue = document.querySelector('.js-send-message-value').value;
      this.fillSendMessageField(oldValue + ' ' + event.detail.selection.value.url + ' ');
      document.querySelector('.js-other-listings-select').value = '';
      document.querySelector('.js-send-message-value').dispatchEvent(new Event('change'));
    });

    document.querySelector('.js-other-listings-select').addEventListener('response', () => {
      LayoutUtils.hide(document.querySelector('.js-other-listing-select-wrap .js-endless-scroll-loader'));
    });
  }

  setBgHeight() {
    const wrap = document.querySelector('.js-two-way-messaging-wrap');
    if (wrap) {
      wrap.style.height
        = Math.max(document.querySelector('html').offsetHeight, document.querySelector('.js-messaging-window').clientHeight + 120) + 'px';
    }
  }

  openMessagingWindow(link) {
    if (!this.#isOpen) {
      this.#isOpen = true;
      fetch(link.href).then(FetchUtils.checkResponseStatus)
        .then((resp) => {
          return resp.json();
        }).then(json => {
          if (json.success) {
            LayoutUtils.hide(document.querySelector('.js-popover-content .js-messaging-loader'));
            document.body.insertAdjacentHTML('beforeend', json.html);
            const win = document.querySelector(this.#windowSelector);
            this.#scrollTimer = null;
            win.dataset.url = json.messages_url;
            win.dataset.sendUrl = json.send_url;
            win.dataset.unreadUrl = json.unread_url;
            win.dataset.readUrl = json.read_url;
            win.dataset.uid = json.showing_uid;
            win.dataset.current_uid = json.showing_uid;
            document.querySelector('.js-phone').textContent = json.lead_phone;
            document.querySelector('.js-name').textContent = json.lead_name;
            document.querySelector('.js-messages').innerHTML = '';
            this.buildInsertFieldSelect(json);
            this.#upLoading = true;

            if (this.#switchery) {
              this.#switchery.destroy();
            }
            this.#switchery = new Switchery(document.querySelector('.js-mark-unread'), { size: 'small' });

            this.initShowingsSelector(json.insert_field_links_url);

            this.initOtherListingSelect(json.listing_search_url);

            this.bindAfterOpenEvents();

            SMDropdown.closeAll();
        
            this.fetchMessages(json.messages_url, {}, true);

            window.onresize = () => {
              this.setBgHeight();
            };
            this.setBgHeight();
            window.scrollTop = 0;
            document.querySelector('.js-two-way-messaging-wrap').scrollTop = 0;
          }
        }).catch((err) => {
          this.#isOpen = false;
          FetchUtils.handleResponseError(err);
        });
    }
  }

  getLastId() {
    const messagesWin = document.querySelector('.js-messages');
    const messages = document.querySelectorAll('.js-message');
    const found = [...messages].reverse().find(m => {
      return (this.isVisible(m, messagesWin) && m.classList.contains('incoming'));
    });
    return found ? found.dataset.id : null;
  }

  getFirstVisibleIncomingMessage() {
    const messagesWin = document.querySelector('.js-messages');
    const messages = document.querySelectorAll('.js-message');
    const found = [...messages].find(m => {
      return this.isVisible(m, messagesWin) && m.classList.contains('incoming');
    });
    return found ? found.dataset.id : null;
  }

  getLastVisibleIncomingMessage() {
    const messagesWin = document.querySelector('.js-messages');
    const messages = document.querySelectorAll('.js-message');
    const found = [...messages].reverse().find(m => {
      return this.isVisible(m, messagesWin) && m.classList.contains('incoming');
    });
    return found ? found.dataset.id : null;
  }

  isDirectionsUp(params) {
    return params && params.direction === 'up';
  }

  addShowingIdParamToUrl(path, id) {
    let url = new URL(path, document.baseURI);
    let params = new URLSearchParams(url.search);
    params.append('id', id);
    url.search = params.toString();
    return url;
  }

  buildInsertFieldSelect(data) {
    let optionsHtml = '<option value="">Insert Field</option>';
    if (data.gallery_url) {
      optionsHtml += `<option value="${data.gallery_url}">Gallery Link</option>`;
    }
    if (data.application_url) {
      optionsHtml += `<option value="${data.application_url}">Application Link for Selected Listing</option>`;
    }
    if (data.schedule_showing_url) {
      optionsHtml += `<option value="${data.schedule_showing_url}">Schedule a Showing Link for Selected Listing</option>`;
    }
    optionsHtml += '<option value="other-listing-application">Application Link for Other Listing</option>';
    optionsHtml += '<option value="other-listing-schedule">Schedule a Showing Link for Other Listing</option>';
    document.querySelector('.js-insert-field').innerHTML = optionsHtml;
  }

  showLoader() {
    const loader = document.querySelector('.js-send-message .js-endless-scroll-loader');
    LayoutUtils.hide(document.querySelector('.js-send-btn-text'));
    LayoutUtils.show(loader);
  }

  hideLoader() {
    const loader = document.querySelector('.js-send-message .js-endless-scroll-loader');
    LayoutUtils.show(document.querySelector('.js-send-btn-text'));
    LayoutUtils.hide(loader);
  }

  showWarningMessage() {
    const warning = document.querySelector('.js-messages-warning');
    warning.style.bottom = document.querySelector('.js-toolbar').offsetHeight + 22 + 'px';
    LayoutUtils.show(warning);
    const warningHeight = warning.offsetHeight + 20;
    document.querySelector('.js-messages').style.marginBottom = warningHeight + 'px';
  }

  hideWarningMessage() {
    LayoutUtils.hide(document.querySelector('.js-messages-warning'));
    document.querySelector('.js-messages').style.marginBottom = '0px';
  }

  fetchMessages(url, params, isInitial = false, forceScrollToBottom = false) {
    this.showLoader();
    fetch(url + '&' + new URLSearchParams(params)).then(FetchUtils.checkResponseStatus)
      .then((resp) => {
        return resp.json();
      }).then(json => {
        this.hideLoader();
        const isDirectionsUp = this.isDirectionsUp(params);
        const placement = isDirectionsUp ? 'afterbegin' : 'beforeend';

        const messages = document.querySelector(`${this.#windowSelector} .js-messages`);
        const storedMessage = document.querySelectorAll('.js-message')[0];

        messages.insertAdjacentHTML(placement, json.html);
        if (isDirectionsUp && json.messages_count === 0) {
          this.#upLoading = false;
        }
        if (!isDirectionsUp) {
          this.markAsRead();
        } else {
          storedMessage.parentNode.scrollTop = storedMessage.offsetTop;
          messages.scrollBy(0, -100);
        }

        if (isInitial) {
          if (!json.outgoing_exist) {
            this.showWarningMessage();
          }
        }
        if (isInitial || forceScrollToBottom) {
          this.scrollToBottomMessages(json.scroll_to);
        }
      }).catch((err) => {
        FetchUtils.handleResponseError(err);
      });
  }

  markAsRead() {
    const win = document.querySelector(this.#windowSelector);
    const id = this.getLastVisibleIncomingMessage();
    const parsedId = id ? parseInt(id.substring(1)) : null;
    if (!parsedId || (this.#last_marked_as_read_message_id && parsedId < this.#last_marked_as_read_message_id)) return;

    this.#last_marked_as_read_message_id = parsedId;
    let url = new URL(win.dataset.readUrl, document.baseURI);
    let params = new URLSearchParams(url.search);
    params.append('message_id', id);
    url.search = params.toString();
    fetch(url, {method: 'PUT', headers: {
      'x-csrf-token': this.#csrf
    }})
      .then(FetchUtils.checkResponseStatus).catch((err) => {
        FetchUtils.handleResponseError(err);
      });
  }

  isVisible(ele, container) {
    const { bottom, height, top } = ele.getBoundingClientRect();
    const containerRect = container.getBoundingClientRect();

    return top <= containerRect.top ? containerRect.top - top <= height : bottom - containerRect.bottom <= height;
  }

  initShowingsSelector(url) {
    const win = document.querySelector(this.#windowSelector);
    const selectEl = document.querySelector('.js-showings-select');
    document.dispatchEvent(new CustomEvent('sm-rich-ui-select:init', {detail: {el: selectEl}}));
    $(selectEl).change(() => {
      win.dataset.current_uid = selectEl.value;
      fetch(url + '?id=' + selectEl.value).then(FetchUtils.checkResponseStatus)
        .then((resp) => {
          return resp.json();
        }).then(json => {
          this.buildInsertFieldSelect(json);
        }).catch((err) => {
          FetchUtils.handleResponseError(err);
        });
    });
  }

  disableUnreadToggle(toggle) {
    toggle.closest('a').classList.add('disabled');
  }

  enableUnreadToggle(toggle) {
    toggle.closest('a').classList.remove('disabled');
  }

  disableSendButton(msg = '') {
    document.querySelector('.js-send-message-value').classList.add('with-disable-message');
    document.querySelector('.js-send-message').classList.add('disabled');
    document.querySelector('.js-disable-message').textContent = msg;
  }

  enableSendButton() {
    document.querySelector('.js-send-message-value').classList.remove('with-disable-message');
    document.querySelector('.js-send-message').classList.remove('disabled');
    document.querySelector('.js-disable-message').textContent = '';
  }

  openMessagingHandler(e) {
    e.preventDefault();
    e.stopPropagation();
    this.openMessagingWindow(e.currentTarget);
    return false;
  }

  initBubbleIcons(containerSelector = 'body') {
    document.querySelectorAll(containerSelector + ' .js-show-text-messaging').forEach(link => {
      link.removeEventListener('click', this.openMessagingHandler);
      link.addEventListener('click', this.openMessagingHandler);
    });
  }

  bindEvents() {
    document.addEventListener('html-updated', (e) => {
      this.initBubbleIcons(e.detail.container);
    });

    document.addEventListener('dropdown:opened', () => {
      const link = document.querySelector('.tippy-content .js-popover-content .js-show-text-messaging-from-dropdown');
      if (link) {
        link.addEventListener('click', e => {
          e.preventDefault();
          LayoutUtils.show(document.querySelector('.js-popover-content .js-messaging-loader'));
          this.openMessagingWindow(link);
          return false;
        });
      }
    });

    window.onkeydown = (e) => {
      var keyCode = e.keyCode || e.which;
      if (keyCode === 27) {
        const win = document.querySelector(this.#windowSelector);
        if (win) {
          win.remove();
          this.#isOpen = false;
        }
      }
    };

    const unreadSidebarFilters = document.querySelectorAll('.js-messaging-unread-filter');
    unreadSidebarFilters.forEach(filter => {
      filter.addEventListener('change', e => {
        const checked = e.target.checked;
        unreadSidebarFilters.forEach(f => f.checked = checked);
        this.disableUnreadToggle(filter);
        fetch(e.target.dataset.url + `?unread_only=${checked}`).then(FetchUtils.checkResponseStatus).then((resp) => {
          return resp.json();
        }).then((json) => {
          if (json.success) {
            const link = document.querySelector('#showing-filter .current');
            if (link) {
              link.click();
            } else {
              window.location.reload();
            }
          }
          this.enableUnreadToggle(filter);
        }).catch((err) => {
          this.enableUnreadToggle(filter);
          FetchUtils.handleResponseError(err);
        });

      });
      new Switchery(filter, { size: 'small' });
    });
  }

  init() {
    const openChat = document.querySelector('.js-open-chat');
    if (openChat) {
      this.openMessagingWindow({href: openChat.dataset.chatUrl});
    }

    this.bindEvents();
    this.initBubbleIcons();
  }
}

export default TwoWayMessaging;