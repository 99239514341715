class InfiniteLoader {
  #page = 1;
  #list;
  #target;
  #observer;

  observeLastListChild(wrap) {
    var items = [...wrap.children].filter((el) => {
      return el.nodeName != 'SCRIPT';
    });
    var lastchild = items[items.length-1];

    this.#target = lastchild;
    this.#observer.observe(lastchild);
  }

  init(list, fetchItems) {
    list.dataset.loaderInited = true;
    this.#list = list;
    this.#observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          this.#page++;
          fetchItems({page: this.#page}, this.#list);
        }
      });
      
    });
    const wrap = list.querySelector('.js-infinite-list-inner');
    if (wrap) {
      this.observeLastListChild(wrap);
    }
    this.bindEvents();
  }

  bindEvents() {
    this.#list.addEventListener('infinite-loader:updated', () => {
      this.#observer.unobserve(this.#target);
      const wrap = this.#list.querySelector('.js-infinite-list-inner');
      this.observeLastListChild(wrap);
    });

    this.#list.addEventListener('infinite-loader:disconnect', () => {
      this.#observer.unobserve(this.#target);
      this.#list.setAttribute('data-loader-disconnected', true);
    });
  }
}

export default InfiniteLoader;