import InfiniteLoader from './infinite_loader';

class InfiniteLoaderHandlers {
  initLoaders(lists, fetchListHandler) {
    lists.forEach(el => {
      if (el.dataset.loaderInited) {
        if (!el.dataset.loaderDisconnected) {
          el.dispatchEvent(new CustomEvent('infinite-loader:updated'));
        }
      } else {
        new InfiniteLoader().init(el, fetchListHandler);
      }
    });
  }

  init(listSelector, fetchListHandler) {
    const lists = document.querySelectorAll(listSelector);
    this.initLoaders(lists, fetchListHandler);

    document.addEventListener('sm-modal:opened', (e) => {
      if (e.detail && e.detail.modalContainer) {
        const lists = e.detail.modalContainer.querySelectorAll(listSelector);
        this.initLoaders(lists, fetchListHandler);
      }
    });

    document.addEventListener('html-updated', (e) => {
      if (e.detail && e.detail.container) {
        const lists = document.querySelectorAll(`${e.detail.container} ${listSelector}`);
        this.initLoaders(lists, fetchListHandler);
      }
    });
  }
}

export default InfiniteLoaderHandlers;