import tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css';

class Tooltips {
  initTootips() {
    const tooltips = document.querySelectorAll('li.tooltip, a.tooltip');
    tooltips.forEach(t => {
      $(t).removeAttr('title');
      tippy(t, {
        allowHTML: true,
        content: $(t).children('div:hidden').html(),
        theme: 'default'
      });
    });
  }

  removeTooltips() {
    document.querySelectorAll('[data-tippy-root]').forEach(t => {
      t.remove();
    });
  }

  bindEvents() {
    document.addEventListener('html-updated', () => {
      this.removeTooltips();
      this.initTootips();
    });
  }

  init() {
    this.initTootips();
    this.bindEvents();
  }
}

export default Tooltips;
