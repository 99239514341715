class Filters {
  init(dashboard) {
    $('.people_input').click(function(event){
      event.stopImmediatePropagation();
      $(this).select();
      return false;
    });

    $('a.icon.people').click(function(){
      $(this).css('opacity', 1);
      $('a.icon.location').css('opacity', 0.2);

      $('#people_search').show();
      $('#filter').hide();

      return false;
    });

    $('a.icon.location').click(function(){
      $(this).css('opacity', 1);
      $('a.icon.people').css('opacity', 0.2);

      $('#people_search').hide();
      $('#filter').show();

      return false;
    });

    if (dashboard.dataset.people && dashboard.dataset.people != '') {
      $('a.icon.people').click();
    } else {
      $('a.icon.location').click();
    }
  }
}

export default Filters;